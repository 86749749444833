/* eslint-disable */
$(function () {

    //updateActiveNav();
    //BindElements();

    //rightPanelStickyScroll('sidebar', '#js-scrolling-panel', '.container .two-thirds');

    $('input.numeric').numeric({
        allowMinus: false,
        allowThouSep: false,
        allowDecSep: false,
        maxDigits: 2,
        max: 99,
        min: 1
    });

    // show active tab on reload
    if (location.hash !== '') {
        $('a[href="' + location.hash + '"]').tab('show');
    } else if ($('.nav-tabs').length > 0) {
        $('.nav-tabs a:first').tab('show');
    }

    // remember the hash in the URL without jumping
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        if (history.pushState) {
            history.pushState(null, null, '#' + $(e.target).attr('href').substr(1));
        } else {
            location.hash = '#' + $(e.target).attr('href').substr(1);
        }
    });

    // remember to back button
    window.onpopstate = function (e) {
        $('a[href="' + location.hash + '"]').tab('show');

        if (location.hash === '' && $('.nav-tabs').length > 0) {
            $('.nav-tabs a:first').tab('show');
        }
    };
});


$("input").click(function () {
    var dataAttr = $(this).attr("data-logic");

    if (dataAttr === "yes") {
        var value = $(this).attr("value");
        alert('Has logic ' + value);
    }

});


$(document).on("click", '.switch-city-link', function (e) {
    e.preventDefault();
    var city = $(this).attr("data-city");
    if (city.length > 0) {
        setSelectedCityReloadPage(city);
    }
});
$(document).on('click', '.js-delete-notice', function(e){

    const isDelete = confirm('Are you sure you want to delete this post?')
    if (!isDelete) {e.preventDefault()}

})
$(document).on('click', '.js-split-thread', function(e){

    const isDelete = confirm('Are you sure you want to move this post and its replies to its own thread?')
    if (!isDelete) {e.preventDefault()}

})


/* TABLE Sorting */
// $(document).on("click", 'thead.sortable a', function (e) {
//     e.preventDefault();
//    var  $this = $(this);
//     console.log($this);

//     var sortColumnData = "ItemID";
//     var sortDirectionData = "asc";


//     if ($this.attr("data-sort-column") === undefined) {
//         //$('thead.sortable th a').removeClass('sort');
//     }
//     else {

//         //Set sort Column
//         sortColumnData = $this.data('sort-column');

//         if ($this.hasClass("desc")) {
//             sortDirectionData = "asc";
//         } else {
//             sortDirectionData = "desc";
//         }

//         var source = "adverts";
//         if ($this.parents("table").attr("id") === "tbl-attendees") {
//             source = "events";
//         }

//         sortColumn($this.data('sort-column'), sortDirectionData, source, "advertlist");

//     }
// });


$(document).on("click", 'input:text', function () {
    $("input:text").focus(function () { $(this).select(); });
});


$(document).on("change", 'select.has-dependencies', function () {

    var ids = $(this).data("display-ids");
    var selectedId = $(this).val();

    let dropdownID = "#" + $(this).attr('id');

    if (selectedId == ids) {
        $(dropdownID + "_Details").slideDown('fast');
    } else {
        $(dropdownID + "_Details").hide();
    }
});


$(document).on("focus", 'input:text', function () {
    $("input:text").focus(function () { $(this).select(); });
});

//$(document).on("click", 'input:text', function () {
//    $("input:text").focus(function () { $(this).select(); });
//});

$(document).on("mouseup", 'input:text', function (e) {
    e.preventDefault();
});


/* Checkbox toggle  */
$(document).on("click", '.styled-checkbox', function () {

    var $this = $(this)[0];
    var checkboxID = '#' + $this.id;

    if ($(checkboxID + "_Details").length > 0) {
        if ($this.checked) {
            //Show any divs with classname
            $(checkboxID + "_Details").slideDown('fast');
        } else {
            //Hide any related details fields
            $(checkboxID + "_Details").slideUp('fast');
        }
    }

    if ($this.id !== undefined && $this.id.indexOf('HasLicense') > 1) {
        if ($this.checked) {
            $(checkboxID + "_NoLicense_Details").slideUp('fast');
        } else {
            $(checkboxID + "_NoLicense_Details").slideDown('fast');
        }
    }

});

//Redirect to nav url if any elemnt in li is clicked
$(document).on("click", '#nav li a', function (e) {
    a = $(this).attr('href');
    //Store Current URL
    localStorage.setItem('currentUrl', a);
    window.location.href = a;
    return false;
});


$(document).on("click", '.panel-toggle', function (e) {
    e.preventDefault();
    var thePanel = $(this).attr('rel');

    $('#' + thePanel).slideToggle('fast');

});


//do the mobile phone stuff
// if (Modernizr.mq('only all and (max-width: 720px)')) {
//     $(document).on("touchstart mousedown", '.touch-button', function (e) {
//         e.preventDefault();

//         var thisBtn = $(this);

//         // Do some other stuff
//         $('.touch-button').not(this).removeClass('active');
//         $('.touch-button').not(this).siblings('.sub-nav').removeClass('active');
//         $('.touch-button').not(this).siblings('a').removeClass('active');


//         if (!thisBtn.hasClass('active')) {
//             // Do some stuff
//             thisBtn.addClass('active');
//             thisBtn.siblings('.sub-nav').addClass('active');
//             thisBtn.siblings('a').addClass('active');
//         }
//         else {
//             // Do some other stuff
//             thisBtn.removeClass('active');
//             thisBtn.siblings('.sub-nav').removeClass('active');
//             thisBtn.siblings('a').removeClass('active');
//         }
//     });
// }

//if (Modernizr.mq('only all and (min-width: 721px) and (max-width: 1024px)')) {
$(document).on("touchstart", 'a.has-sub-nav', function (e) {
    e.preventDefault();

    var thisBtn = $(this);

    // Do some other stuff
    $('a.has-sub-nav').not(this).removeClass('active');
    $('a.has-sub-nav').not(this).siblings('.sub-nav').removeClass('active');
    $('a.has-sub-nav').not(this).parent('li').removeClass('active');

    if (!thisBtn.hasClass('active')) {
        // Do some stuff
        thisBtn.addClass('active');
        thisBtn.siblings('.sub-nav').addClass('active');
        thisBtn.parent('li').addClass('active');
    }
    else
    {
        // Do some other stuff
        thisBtn.removeClass('active');
        thisBtn.siblings('.sub-nav').removeClass('active');
        thisBtn.parent('li').removeClass('active');
    }

});
//}
function sortColumn(sortBy, sortDirection, source) {

    var currentSortColumn = "ItemID";
    var currentSortOrder = "asc";

    //loadEventAttendeeList
    if (source === "events") {
        $.get('/my-unipol/setsortorder/' + sortBy + '/' + sortDirection, function (data) {
            currentSortColumn = "Name";
            currentSortOrder = "asc";

            loadEventAttendeeList();
        });
    } else {
        $.get('/my-unipol/setsortorder/' + sortBy + '/' + sortDirection, function (data) {
            advertlist();
        });
    }
}