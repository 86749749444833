/* eslint-disable */
export function getParameterByName(name) {
	var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
	return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

$('.form-control--password').passStrengthify();

function globalSearch() {
	$(document).on("keypress", '#mobile-search', function (e) {
		if (e.keyCode === 13) {
			var search = $("#mobile-search").val();
			window.location.replace("/search?searchtext=" + search);
		}
	});

	$(document).on("click", ".js-search-button", function (e) {
		e.preventDefault()
		var search = $('#searchtext').val();
		window.location.replace("/search?searchtext=" + search);

	});

	$(document).on("click", "#btn-search-mobile", function (e) {
		e.preventDefault();
		window.location.replace("/search");

	});

	$(document).on("keypress", '#searchtext', function (e) {
		if (e.keyCode === 13) {
			e.preventDefault();
			var search = $("#searchtext").val();
			window.location.replace("/search?searchtext=" + search);
		}
	});
}
$(function () {

	// Legacy City Filter hider
	if ($("a.switch-city-link").length > 0) {
		$.get('/my-unipol/selectedcity', function (currentCity) {
			$("a.switch-city-link[data-city='" + currentCity + "']").hide();
		});
	}

	$('nav.megamenu').on('mouseenter mouseleave', '.dropdown', function (e) {
		var dropdown = $(e.target).closest('.dropdown');
		var menu = $('.dropdown-menu', dropdown);
		dropdown.addClass('show');
		menu.addClass('show');
		setTimeout(function () {
			dropdown[dropdown.is(':hover') ? 'addClass' : 'removeClass']('show');
			menu[dropdown.is(':hover') ? 'addClass' : 'removeClass']('show');
		}, 250);
	}).on('click', '.dropdown', function (e) {
		if ($(window).width() > 768) {
			var $href = $(this).find('.nav-link').attr('href');
			window.open($href, '_self');
		}
	});

	$('#search-panel-city').change(function (e) {
		if ($(this).val() !== "") {
			window.unipolSharedNamespace.setSelectedCityReloadPage($(this).val());
			//$(this).closest('form').submit();
		}
	});

	globalSearch();

	if ($('.datepicker').length > 0) {
		$('.datepicker').datepicker({
			language: 'en-GB',
			format: 'dd/mm/yyyy'
		});
	}

	if ($('.ckeditor').length > 0) {
		CKEDITOR.replaceClass = 'ckeditor';
	}

	// Hack for webforms checkbox classes
	if ($('span.styled-checkbox').length > 0) {
		var $styledSpan = $('span.styled-checkbox');
		$styledSpan.find('input[type=checkbox]').addClass('styled-checkbox').insertAfter($styledSpan);
	}

	// Hack for webforms datepicker classes
	if ($('.EditingFormControlNestedControl .datepicker').length > 0) {
		var theDateElem = $('.EditingFormControlNestedControl .datepicker');
		var theParentElem = theDateElem.parent();
		var theDateIcon = '<div class="input-group-append datepicker-trigger"><button type="button" class="px-3" disabled=""><i class="material-icons" aria-hidden="true">date_range</i></button></div>';

		if (!theParentElem.hasClass('input-group')) {
			theParentElem.addClass('input-group w-75');
		}

		if (theParentElem.find('.datepicker-trigger').length === 0) {
			theParentElem.append(theDateIcon);
		}
	}

	// remove the empty p tags
	//$('.property-details-container p').each(function () {
	//	var $this = $(this);
	//	if ($this.html().replace(/\s|&nbsp;/g, '').length === 0)
	//		$this.remove();
	//});
});
